import React from "react";
import theme from "./theme";
import { Box, ThemeProvider } from "@mui/material";
import { Navigate, Route, Routes } from "react-router-dom";
import LazySpinner from "./components/LazySpinner";
import EnterScreen from "./components/EnterScreen";
import AppTemplate from "./components/TeachVideoApp/AppTemplate";
import ChooseLayout from "./components/TeachVideoApp/ChooseLayout";
import StudyLayout from "./components/TeachVideoApp/StudyLayout";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <React.Suspense
        fallback={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <LazySpinner />
          </Box>
        }
      >
        <Routes>
          <Route path="/" element={<EnterScreen />} />
          <Route element={<AppTemplate />}>
            <Route path="choose-problem">
              <Route index element={<ChooseLayout />} />
              <Route path=":problem" element={<StudyLayout />} />
              <Route path=":problem/:solution" element={<StudyLayout />} />
            </Route>
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </React.Suspense>
    </ThemeProvider>
  );
}

export default App;
