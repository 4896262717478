const categories = () => {
  return [
    {
      key: "spacing",
      preview: require("./Spaces/spaces.png"),
      video: require("./Spaces/Problem/spasesProblem.mp4"),
      poster: require("./Spaces/Problem/spasesProblem_preview1.jpg"),
      endPoster: require("./Spaces/Problem/spasesProblem_preview2.jpg"),
      title: "Spacing",
    },
    {
      key: "overbite",
      preview: require("./Overbite/overbite.png"),
      video: require("./Overbite/Problem/overbiteProblem.mp4"),
      poster: require("./Overbite/Problem/overbiteProblem_preview1.jpg"),
      endPoster: require("./Overbite/Problem/overbiteProblem_preview2.jpg"),
      title: "Overbite",
    },
    {
      key: "openbite",
      preview: require("./Openbite/openbite.png"),
      video: require("./Openbite/Problem/openbiteProblem.mp4"),
      poster: require("./Openbite/Problem/openbiteProblem_preview1.jpg"),
      endPoster: require("./Openbite/Problem/openbiteProblem_preview2.jpg"),
      title: "Openbite",
    },
    {
      key: "crowding",
      preview: require("./Crowding/crowding.png"),
      video: require("./Crowding/Problem/crowdingProblem.mp4"),
      poster: require("./Crowding/Problem/CrowdingProblemPreview1.jpg"),
      endPoster: require("./Crowding/Problem/CrowdingProblemPreview2.jpg"),
      title: "Crowding",
    },
    {
      key: "crossbite",
      preview: require("./Crossbite/crossbite.png"),
      video: require("./Crossbite/Problem/crossbiteProblem.mp4"),
      poster: require("./Crossbite/Problem/crossbiteProblem_preview1.jpg"),
      endPoster: require("./Crossbite/Problem/crossbiteProblem_preview2.jpg"),
      title: "Crossbite",
    },
    {
      key: "class2",
      preview: require("./Class2/class2.png"),
      video: require("./Class2/Problem/Problem.mp4"),
      poster: require("./Class2/Problem/ClassIIpreview1.jpg"),
      endPoster: require("./Class2/Problem/ClassIIpreview2.jpg"),
      title: "Angle Class II",
    },
    {
      key: "class3",
      preview: require("./Class3/class3.png"),
      video: require("./Class3/Problem/Problem.mp4"),
      poster: require("./Class3/Problem/Class3preview1.jpg"),
      endPoster: require("./Class3/Problem/Class3preview2.jpg"),
      title: "Angle Class III",
    },
  ];
};

const solutions = () => {
  return [
    {
      key: "spacingS1",
      category: "spacing",
      title: "Solution 1",
      description: "Close all spaces",
      preview: require("./Spaces/Close All Spaces/preview.png"),
      video: require("./Spaces/Close All Spaces/closeSpeces.mp4"),
      poster: require("./Spaces/Problem/spasesProblem_preview2.jpg"),
      endPoster: require("./Spaces/Problem/spasesProblem_preview1.jpg"),
    },
    {
      key: "spacingS2",
      category: "spacing",
      title: "Solution 2",
      description: "Keep spaces distal to lateral incisors",
      preview: require("./Spaces/Spaces distal lateral incisors/preview.png"),
      video: require("./Spaces/Spaces distal lateral incisors/spacesDistalLateralIncisors.mp4"),
      poster: require("./Spaces/Problem/spasesProblem_preview2.jpg"),
      endPoster: require("./Spaces/Problem/spasesProblem_preview1.jpg"),
    },
    {
      key: "spacingS3",
      category: "spacing",
      title: "Solution 3",
      description: "Keep spaces equal around the lateral incisors ",
      preview: require("./Spaces/Spaces around lateral incisors/preview.png"),
      video: require("./Spaces/Spaces around lateral incisors/spacesAroundLateralIncisors.mp4"),
      poster: require("./Spaces/Problem/spasesProblem_preview2.jpg"),
      endPoster: require("./Spaces/Problem/spasesProblem_preview1.jpg"),
    },
    {
      key: "spacingS4",
      category: "spacing",
      title: "Solution 4",
      description: "Keep the spaces distal to the canines",
      preview: require("./Spaces/Spaces distal canines/preview.png"),
      video: require("./Spaces/Spaces distal canines/spacesDistalCanines.mp4"),
      poster: require("./Spaces/Problem/spasesProblem_preview2.jpg"),
      endPoster: require("./Spaces/Problem/spasesProblem_preview1.jpg"),
    },
    {
      key: "overbiteS1",
      category: "overbite",
      title: "Solution 1",
      description: "Upper intrusion of incisors",
      preview: require("./Overbite/Overbite Upper intrusion incisors/preview.png"),
      video: require("./Overbite/Overbite Upper intrusion incisors/overbiteUpperIntrusionIncisors.mp4"),
      poster: require("./Overbite/Problem/overbiteProblem_preview2.jpg"),
      endPoster: require("./Overbite/Problem/overbiteProblem_preview1.jpg"),
    },
    {
      key: "overbiteS2",
      category: "overbite",
      title: "Solution 2",
      description: "Lower intrusion of incisors",
      preview: require("./Overbite/Overbite Lower intrusion incisors/preview.png"),
      video: require("./Overbite/Overbite Lower intrusion incisors/overbiteLowerIntrusionIncisors.mp4"),
      poster: require("./Overbite/Problem/overbiteProblem_preview2.jpg"),
      endPoster: require("./Overbite/Problem/overbiteProblem_preview1.jpg"),
    },
    {
      key: "overbiteS3",
      category: "overbite",
      title: "Solution 3",
      description: "Upper and lower intrusion at the same time",
      preview: require("./Overbite/Overbite Upper Lower intrusion incisors/preview.png"),
      video: require("./Overbite/Overbite Upper Lower intrusion incisors/overbiteUpperLowerIntrusionIncisors.mp4"),
      poster: require("./Overbite/Problem/overbiteProblem_preview2.jpg"),
      endPoster: require("./Overbite/Problem/overbiteProblem_preview1.jpg"),
    },
    {
      key: "openbiteS1",
      category: "openbite",
      title: "Solution 1",
      description: "Upper extrusion of incisors",
      preview: require("./Openbite/Openbite Upper extrusion of incisors/preview.png"),
      video: require("./Openbite/Openbite Upper extrusion of incisors/openbiteUpper ExtrusionIncisors.mp4"),
      poster: require("./Openbite/Problem/openbiteProblem_preview2.jpg"),
      endPoster: require("./Openbite/Problem/openbiteProblem_preview1.jpg"),
    },
    {
      key: "openbiteS2",
      category: "openbite",
      title: "Solution 2",
      description: "Lower extrusion of incisors",
      preview: require("./Openbite/Openbite Lower extrusion of incisors/preview.png"),
      video: require("./Openbite/Openbite Lower extrusion of incisors/openbiteLower ExtrusionIncisors.mp4"),
      poster: require("./Openbite/Problem/openbiteProblem_preview2.jpg"),
      endPoster: require("./Openbite/Problem/openbiteProblem_preview1.jpg"),
    },
    {
      key: "openbiteS3",
      category: "openbite",
      title: "Solution 3",
      description: "Upper and lower extrusion of incisors",
      preview: require("./Openbite/Openbite Upper and Lower extrusion of incisors/preview.png"),
      video: require("./Openbite/Openbite Upper and Lower extrusion of incisors/openbiteUpperLower ExtrusionIncisors.mp4"),
      poster: require("./Openbite/Problem/openbiteProblem_preview2.jpg"),
      endPoster: require("./Openbite/Problem/openbiteProblem_preview1.jpg"),
    },
    {
      key: "crowdingS1",
      category: "crowding",
      title: "Solution 1",
      description: "Expansion and stripping",
      preview: require("./Crowding/Expansion and stripping/preview.png"),
      video: require("./Crowding/Expansion and stripping/Expansion and stripping.mp4"),
      poster: require("./Crowding/Problem/CrowdingProblemPreview2.jpg"),
      endPoster: require("./Crowding/Problem/CrowdingProblemPreview1.jpg"),
    },
    {
      key: "crowdingS2",
      category: "crowding",
      title: "Solution 2",
      description: "Expansion and proclinaition",
      preview: require("./Crowding/Expansion and proclinaition/preview.png"),
      video: require("./Crowding/Expansion and proclinaition/crowdingExpansionProclination.mp4"),
      poster: require("./Crowding/Problem/CrowdingProblemPreview2.jpg"),
      endPoster: require("./Crowding/Problem/CrowdingProblemPreview1.jpg"),
    },
    {
      key: "crowdingS3",
      category: "crowding",
      title: "Solution 3",
      description: "Extraction of lower incisors to resolve the lower anterior crowding",
      preview: require("./Crowding/Extraction of lower incisors/preview.png"),
      video: require("./Crowding/Extraction of lower incisors/crowdingExtraction.mp4"),
      poster: require("./Crowding/Problem/CrowdingProblemPreview2.jpg"),
      endPoster: require("./Crowding/Problem/CrowdingProblemPreview1.jpg"),
    },
    {
      key: "crossbiteS1",
      category: "crossbite",
      title: "Solution 1",
      description: "Use elastics, and expansion to correct the cross bite",
      preview: require("./Crossbite/Solution 1/preview.png"),
      video: require("./Crossbite/Solution 1/crossbiteSolution.mp4"),
      poster: require("./Crossbite/Problem/crossbiteProblem_preview2.jpg"),
      endPoster: require("./Crossbite/Problem/crossbiteProblem_preview1.jpg"),
    },
    {
      key: "class2S1",
      category: "class2",
      title: "Solution 1",
      description: "Class 2 elastics and stripping",
      preview: require("./Class2/Class 2 elastics and stripping/preview.png"),
      video: require("./Class2/Class 2 elastics and stripping/elasticsStripping.mp4"),
      poster: require("./Class2/Problem/ClassIIpreview2.jpg"),
      endPoster: require("./Class2/Problem/ClassIIpreview1.jpg"),
    },
    {
      key: "class2S2",
      category: "class2",
      title: "Solution 2",
      description: "Distalisation and anetro-posterior jump",
      preview: require("./Class2/Distalisation and anetro-posterior jump/preview.png"),
      video: require("./Class2/Distalisation and anetro-posterior jump/anetro-posteriorJump.mp4"),
      poster: require("./Class2/Problem/ClassIIpreview2.jpg"),
      endPoster: require("./Class2/Problem/ClassIIpreview1.jpg"),
    },
    {
      key: "class2S3",
      category: "class2",
      title: "Solution 3",
      description: "Extraction of 14,24 and space closure",
      preview: require("./Class2/Extraction of 14,24 and space closure/preview.png"),
      video: require("./Class2/Extraction of 14,24 and space closure/Extraction14,24.mp4"),
      poster: require("./Class2/Problem/ClassIIpreview2.jpg"),
      endPoster: require("./Class2/Problem/ClassIIpreview1.jpg"),
    },
    {
      key: "class3S1",
      category: "class3",
      title: "Solution 1",
      description: "Class 3 elastics and stripping",
      preview: require("./Class3/Class 3 elastics and stripping/preview.png"),
      video: require("./Class3/Class 3 elastics and stripping/Class3ElasticsStripping.mp4"),
      poster: require("./Class3/Problem/Class3preview2.jpg"),
      endPoster: require("./Class3/Problem/Class3preview1.jpg"),
    },
    {
      key: "class3S2",
      category: "class3",
      title: "Solution 2",
      description: "Extraction of 34,44 teeth and space closure",
      preview: require("./Class3/Extraction of 34,44 and space closure/preview.png"),
      video: require("./Class3/Extraction of 34,44 and space closure/extraction34,44.mp4"),
      poster: require("./Class3/Problem/Class3preview2.jpg"),
      endPoster: require("./Class3/Problem/Class3preview1.jpg"),
    },
    {
      key: "class3S3",
      category: "class3",
      title: "Solution 3",
      description: "Extraction of lower incisor",
      preview: require("./Class3/Extraction of lower incisor/preview.png"),
      video: require("./Class3/Extraction of lower incisor/extractionLowerIncisor.mp4"),
      poster: require("./Class3/Problem/Class3preview2.jpg"),
      endPoster: require("./Class3/Problem/Class3preview1.jpg"),
    },
  ];
};

export function getCategoriesList() {
  return categories();
}

export function getCategoryById(id) {
  return categories().find((el) => el.key === id);
}

export function getSolutionsByCategory(category) {
  return solutions().filter((el) => el.category === category);
}

export function getSolutionById(solution) {
  return solutions().find((el) => el.key === solution);
}
