import React from "react";
import "./index.scss";
import { Button, Typography } from "@mui/material";
import mainAlignersImg from "./assets/main_aligners_img.png";
import logo from "./assets/logo_white.png";
import dividerGradientImg from "./assets/divider.png";
import { Link } from "react-router-dom";
import bgPoster from "./assets/bgPoster.jpg";
import bgVideo from "./assets/bgVideo.mp4";

function MainPage() {
  return (
    <div className="mainPage">
      <video className="mainPage-bgVideo" poster={bgPoster} autoPlay muted loop>
        <source src={bgVideo} type="video/mp4" />
      </video>
      <div className="mainPage-close">
        <Button variant="outlined" color="white" size="small" fullWidth>
          Close
        </Button>
      </div>
      <div className="mainPage-centerWrapper">
        <img src={mainAlignersImg} className="mainPage-headingImg" alt="" />
        <img src={logo} className="mainPage-logo" alt="" />
        <img
          src={dividerGradientImg}
          className="mainPage-divideGradient"
          alt=""
        />
        {/* TODO FTW IS HERE? */}
        <Typography
          variant="h3"
          component="div"
          textAlign="center"
          fontWeight="bold"
          sx={{ color: "#fff" }}
        >
          Explanatory module
        </Typography>
        <Link to={"/choose-problem"} className="btnStart">
          Let's start
        </Link>
      </div>
    </div>
  );
}

export default MainPage;
