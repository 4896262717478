import { Typography } from "@mui/material";
import "./StudyBottomBar.scss";

function StudyBottomBar({
  isActive,
  solutionsList,
  selectSolution = () => {},
}) {
  return (
    <div className={`studyBottomBar ${isActive ? "active" : ""}`}>
      <Typography
        variant="h4"
        component="div"
        gutterBottom
        fontWeight="bold"
        color="white"
        sx={{ color: "#fff" }}
      >
        Study solutions
      </Typography>
      <div className="proposeList">
        {solutionsList.map((solution) => (
          <button
            key={solution.key}
            className="proposeList-item"
            onClick={() => {
              selectSolution(solution.key);
            }}
          >
            <img
              src={solution.preview}
              className="proposeList-item-preview"
              alt=""
            />
            <div className="proposeList-item-content">
              <Typography
                variant="h6"
                component="div"
                gutterBottom
                fontWeight="bold"
                color="white"
                sx={{ color: "#fff" }}
              >
                {solution.title}
              </Typography>
              <Typography
                variant="subtitle2"
                component="div"
                color="white"
                sx={{ color: "#fff" }}
              >
                {solution.description}
              </Typography>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
}

export default StudyBottomBar;
