import { Outlet } from "react-router-dom";
import leftBarLogo from "./assets/logo_white_vertical.png";
import "./AppTemplate.scss";

function AppTemplate() {
  return (
    <div className="appTemplate">
      <div className="appTemplate-leftBar">
        <img src={leftBarLogo} alt="" />
      </div>
      <div className="appTemplate-content">
        <Outlet />
      </div>
    </div>
  );
}

export default AppTemplate;
