import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
    white: {
      light: "#fff",
      main: "#fff",
      dark: "#dcdcdc",
      contrastText: "#969696",
    },
  },
  shape: {
    borderRadius: 24,
  },
});

export default theme;
