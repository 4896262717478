import { Button, Typography } from "@mui/material";
import "./StudyTopBar.scss";
import { Link as RouterLink } from "react-router-dom";

function StudyTopBar({ isActive = false, title, selectProblem = () => {} }) {
  return (
    <div className={`studyTopBar ${isActive ? "active" : ""}`}>
      <div className="studyTopBar-leftAction">
        <Button
          to="/choose-problem"
          component={RouterLink}
          variant="outlined"
          color="white"
        >
          Back to problems
        </Button>
      </div>
      <button className="studyTopBar-heading" onClick={selectProblem}>
        <img src={require("../../videoLib/Openbite/openbite.png")} alt="" />
        <Typography
          variant="h6"
          component="div"
          color="white"
          fontWeight="bold"
          sx={{ color: "#fff" }}
        >
          Study the problem: {title}
        </Typography>
      </button>
      <div className="studyTopBar-rightAction">
        <Button
          to={"/"}
          component={RouterLink}
          variant="outlined"
          color="white"
        >
          Close
        </Button>
      </div>
    </div>
  );
}

export default StudyTopBar;
