import "./StudyLayout.scss";
import StudyTopBar from "./StudyTopBar";
import StudyBottomBar from "./StudyBottomBar";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Box, Button, Slider } from "@mui/material";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  getCategoryById,
  getSolutionById,
  getSolutionsByCategory,
} from "../../videoLib/template";

function getContent(problem, solution) {
  const categoryData = getCategoryById(problem);
  if (!solution) {
    return {
      video: categoryData.video,
      poster: categoryData.poster,
      endPoster: categoryData.endPoster,
    };
  }

  const solutionData = getSolutionById(solution);

  return {
    video: solutionData.video,
    poster: solutionData.poster,
    endPoster: solutionData.endPoster,
  };
}

function StudyPage() {
  const videoRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();

  const [isVideoStopped, setIsVideoStopped] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);

  const { problem, solution } = useParams();

  const [video, setVideo] = useState(null);
  const [poster, setPoster] = useState(null);

  useEffect(() => {
    const { video, poster } = getContent(problem, solution);
    setVideo(video);
    setPoster(poster);
  }, [problem, solution]);

  const title = useMemo(() => {
    const categoryData = getCategoryById(problem);
    return categoryData.title;
  }, [problem]);

  const solutions = useMemo(() => {
    return getSolutionsByCategory(problem);
  }, [problem]);

  function playVideo() {
    const videoPlayer = videoRef?.current;
    if (videoPlayer) {
      videoPlayer.play();
      setIsPlaying(true);
      setIsVideoStopped(false);
    }
  }

  function pauseVideo() {
    const videoPlayer = videoRef?.current;
    if (videoPlayer) {
      videoPlayer.pause();
      setIsPlaying(false);
    }
  }

  function stopVideo() {
    const videoPlayer = videoRef?.current;
    if (videoPlayer) {
      videoPlayer.currentTime = videoPlayer.duration;
      setIsVideoStopped(true);
    }
  }

  useEffect(() => {
    const videoPlayer = videoRef?.current;
    function onEnd() {
      setIsPlaying(false);
      setIsVideoStopped(true);

      const { endPoster } = getContent(problem, solution);
      setPoster(endPoster);
    }
    if (videoPlayer) {
      videoPlayer.addEventListener("ended", onEnd);
      setIsVideoStopped(false);
      return () => {
        videoPlayer.removeEventListener("ended", onEnd);
      };
    }
  }, [problem, solution, videoRef]);

  useLayoutEffect(() => {
    const videoPlayer = videoRef?.current;
    if (videoPlayer && video) {
      setIsVideoStopped(false);
      setIsPlaying(true);
      videoPlayer.load();
      videoPlayer.play();
    }
  }, [videoRef, video]);

  const [currentPath, setCurrentPath] = useState(location.pathname);
  function navigateToVideo(path) {
    navigate(path);
    if (currentPath === path) {
      playVideo();
    }
    setCurrentPath(path);
  }

  const [videoProgress, changeVideoProgress] = useState(0);
  function onVideoProgressChange(e, val) {
    const videoPlayer = videoRef?.current;

    videoPlayer.currentTime = Math.floor((videoPlayer.duration / 100) * val);
  }

  useEffect(() => {
    const videoPlayer = videoRef?.current;
    if (!videoPlayer) {
      return;
    }

    function updateProgressBar() {
      const percentage = Math.floor(
        (100 / videoPlayer.duration) * videoPlayer.currentTime
      );
      if (!percentage) {
        return;
      }
      changeVideoProgress(percentage);
    }

    videoPlayer.addEventListener("timeupdate", updateProgressBar, false);
    return () => {
      videoPlayer.addEventListener("timeupdate", updateProgressBar);
    };
  }, []);

  return (
    <div className="studyLayout">
      <StudyTopBar
        isActive={isVideoStopped}
        title={title}
        selectProblem={() => {
          navigateToVideo(`/choose-problem/${problem}`);
        }}
      />
      {/*{isVideoStopped && poster && (*/}
      {/*  <img src={poster} className="studyLayout-poster" alt="" />*/}
      {/*)}*/}
      <video ref={videoRef} className="studyLayout-video" muted poster={poster}>
        <source src={video} type="video/mp4" />
      </video>

      {!isVideoStopped && (
        <Box
          sx={{
            position: "absolute",
            left: "50%",
            bottom: 80,
            transform: "translateX(-50%)",
          }}
        >
          <Box sx={{ px: 2, width: "100%" }}>
            <Slider
              aria-label="videoTimeTrack"
              value={videoProgress}
              onChange={onVideoProgressChange}
            />
          </Box>
          {!isPlaying ? (
            <Button
              variant="outlined"
              color="white"
              size="large"
              onClick={playVideo}
            >
              Play
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="white"
              size="large"
              onClick={pauseVideo}
            >
              Pause
            </Button>
          )}

          <Button
            variant="outlined"
            color="white"
            size="large"
            onClick={stopVideo}
            sx={{ ml: 2 }}
          >
            Solve the problem
          </Button>
        </Box>
      )}

      <StudyBottomBar
        isActive={isVideoStopped}
        solutionsList={solutions}
        selectSolution={(solution) => {
          navigateToVideo(`/choose-problem/${problem}/${solution}`);
        }}
      />
    </div>
  );
}

export default StudyPage;
