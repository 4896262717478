import { Typography } from "@mui/material";
import { useMemo } from "react";
import { getCategoriesList } from "../../videoLib/template";
import "./ChooseLayout.scss";
import { Link } from "react-router-dom";

function ChoosePage() {
  const itemsList = useMemo(() => {
    return getCategoriesList();
  }, []);

  return (
    <div className="chooseLayout">
      <Typography
        variant="h4"
        component="div"
        color="white"
        fontWeight="bold"
        textAlign="center"
        sx={{ color: "#fff", marginTop: "auto", marginBottom: "auto", py: 2 }}
        gutterBottom
      >
        CHOOSE THE PROBLEM
      </Typography>

      <div className="selectionList">
        {itemsList.map((item) => (
          <Link key={item.key} to={item.key} className="selectionList-item">
            <img src={item.preview} alt="" />
            <Typography
              variant="h6"
              component="div"
              color="white"
              fontWeight="bold"
              textAlign="center"
              sx={{ color: "#fff" }}
            >
              {item.title}
            </Typography>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default ChoosePage;
